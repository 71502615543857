<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text5 q-mr-auto q-ma-sm">Baixa de Pagamentos</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Baixa de Pagamentos" exact />
      </q-breadcrumbs>
    </div>
    <q-page padding>
      <header-pay-invoices></header-pay-invoices>
      <list-pay-invoices></list-pay-invoices>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderPayInvoices from '../../components/financial/HeaderPayInvoices.vue';
import ListPayInvoices from '../../components/financial/ListPayInvoices.vue';

export default {
  name: 'PayInvoices',
  components: { HeaderPayInvoices, ListPayInvoices }
};
</script>
