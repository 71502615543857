<template>
  <div class="q-mb-sm">
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Faturas</q-tooltip>
      </q-btn>
      <!-- @click="ActionSetDialogCampaign(true)" -->
    </q-toolbar>
    <q-card flat v-if="filter" bordered>
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <!-- <select-request
                      :v-model="params.affiliate"
                      outlined
                      label="Nome do afiliado"
                      endpoint="/redirect?app=AFFILIATE&path=/api/get/affiliate"
                      option-label="name"
                      option-value="id"
                      clearable
                    /> -->
          <q-select
            v-model="params.affiliate"
            use-input
            input-debounce="200"
            @filter="filterAffiliates"
            :options="affiliatesFiltered"
            label="Nome do afiliado"
            outlined
            option-label="name"
            option-value="id"
            clearable
            @input="(value) => (params.affiliateNameId = value.id)"
          />
        </div>
        <div class="col-6">
          <q-input v-model="params.affiliateId" outlined label="ID do afiliado" type="text" clearable />
        </div>
        <div class="col-6">
          <q-input v-model="params.invoiceId" outlined label="Número da fatura" type="text" clearable />
        </div>
        <div class="col-6">
          <q-select
            v-model="params.invoiceStatus"
            label="Status da fatura"
            outlined
            :options="optionsStatus"
            clearable
          />
          <!-- :options="['Fatura paga', 'Fatura não paga']" -->
        </div>
        <div class="col-6">
          <date-input
            buttons
            outlined
            label="Geração da fatura"
            :v-model="params.invoiceDate"
            @update="updateData"
            clearable
            range
          />
        </div>
        <div class="col-6">
          <date-input
            buttons
            outlined
            label="Data de pagamento"
            :v-model="params.paymentDate"
            @update="(value) => (params.paymentDate = value)"
            clearable
            range
          />
        </div>
        <div class="col-12 text-right">
          <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" @click="onFilterInvoices" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
//import SelectRequest from "@/widgets/inputsGlobal/SelectRequest.vue";
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderPayInvoices',
  mixins: [FinancialMixin],
  components: { DateInput },
  data() {
    return {
      filter: true,
      affiliates: [],
      affiliatesFiltered: [],
      params: {
        affiliateNameId: null,
        affiliate: null,
        affiliateId: null,
        invoiceId: null,
        invoiceStatus: null,
        invoiceDate: null,
        paymentDate: null
      },

      optionsStatus: [
        {
          label: 'Fatura Agendada',
          value: '4'
        },
        {
          label: 'Fatura Paga',
          value: '5'
        },
        {
          label: 'Fatura não Paga',
          value: '12'
        }
      ]
    };
  },
  methods: {
    updateData(value) {
      this.params.invoiceDate = value;
      console.log('value', value);
    },
    filterAffiliates(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.affiliatesFiltered = this.affiliates;
        this.affiliatesFiltered = this.affiliates.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    async getAffiliates() {
      try {
        const { data, status } = await this.$http.get('/redirect?app=AFFILIATE&path=/api/get/affiliate');
        if (status === 200)
          this.affiliates = data.map((item) => ({
            id: item.id,
            name: `${item.name} - ${item.business_name ? item.business_name : ''}  (ID: ${item.id}) `
          }));
      } catch (error) {
        console.error(error);
      }
    },

    clearFilter() {
      this.params = {
        affiliate: null,
        affiliateId: null,
        invoiceId: null,
        invoiceStatus: null,
        invoiceDate: null,
        paymentDate: null
      };

      let filterSelect = [];
      filterSelect.push('page=1');
      filterSelect.push('per_page=10');
      filterSelect.push('order_by=trademark%7Casc');
      filterSelect.push('states=%5B4%5D');

      this.ActionSetFilterInvoicesPaymentsStep(filterSelect.join('%26'));
      this.getInvoicesPaymentsStep();
    },

    onFilterInvoices() {
      let filterSelect = [];

      if (this.params.affiliate) filterSelect.push(`affiliate_id=${this.params.affiliate.id}`);
      else if (this.params.affiliateId) filterSelect.push(`affiliate_id=${this.params.affiliateId}`);

      if (this.params.invoiceId) filterSelect.push(`id=${this.params.invoiceId}`);

      if (this.params.invoiceStatus) filterSelect.push(`states=[${this.params.invoiceStatus.value}]`);

      if (this.params.invoiceDate && !this.params.invoiceDate.includes('Não Informado')) {
        let [from, , to] = this.params.invoiceDate.split(' ');
        from = this.formatToEn(from);
        to = this.formatToEn(to);
        //filterSelect.push(`created_at=%7B%22start%22%3A%22${from}%22%2C%22end%22%3A%22${to}%22%7D`);
        filterSelect.push(`created_at={"start":"${from}","end":"${to}"}`);
      }

      if (this.params.paymentDate && !this.params.paymentDate.includes('Não Informado')) {
        let [from, , to] = this.params.paymentDate.split(' ');
        from = this.formatToEn(from);
        to = this.formatToEn(to);
        filterSelect.push(`payment_day={"start":"${from}","end":"${to}"}`);
      }

      // Needs to change de '&' to %26 to work
      this.ActionSetFilterInvoicesPaymentsStep(filterSelect.join('%26'));
      this.getInvoicesPaymentsStep();
    }
  },

  created() {
    this.getAffiliates();
  }
};
</script>

<style></style>
