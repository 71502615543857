<template>
  <div>
    <q-table
      flat
      bordered
      :data="invoicesPaymentsStep"
      :columns="columns"
      row-key="id"
      :pagination="pagination"
      no-data-label="Sem faturas para liberação"
    >
      <template v-slot:body-cell-date="props">
        <q-td :props="props" color="blue-1">
          <q-chip v-if="props.row.payment_day" clickable dense color="primary" class="text-white">
            {{ props.row.payment_day }}
          </q-chip>
          <q-popup-edit v-model="props.row.payment_day" title="Nova data de pagamento">
            <date-input v-model="params.activation_date" outlined label="Nova data de pagamento" />
          </q-popup-edit>
        </q-td>
      </template>

      <template v-slot:body-cell-fatura="props">
        <q-td :props="props">
          <div class="ellipsis" style="max-width: 100px" v-if="props.row.invoice_payments_ids.split('').length > 12">
            {{ props.row.invoice_payments_ids.split(',').join(', ') }}
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
              {{ props.row.invoice_payments_ids.split(',').join(', ') }}
            </q-tooltip>
          </div>
          <div v-else>
            {{ props.row.invoice_payments_ids.split(',').join(', ') }}
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-actions="props">
        <q-td :props="props">
          <div v-if="props.row.id_states != '5'">
            <q-radio v-model="props.row.payout" val="1" label="Baixar Fatura" />
            <q-radio v-model="props.row.payout" val="2" label="Não Baixar Fatura" />
          </div>
          <div v-else>-</div>
        </q-td>
      </template>

      <template v-slot:bottom>
        <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
          Total:{{ reqPagination.total }}
          <q-space />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>
    <div class="q-ma-md">
      <q-btn
        color="positive"
        label="Enviar Faturas"
        @click="onBtnSendClicked"
        no-caps
        unelevated
        no-wrap
        class="q-px-md"
        :disable="this.invoicesPaymentsStep.length > 0 ? false : true"
      />
    </div>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
//import PaginationTable from "../../widgets/PaginationTable.vue";
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'ListPayInvoices',
  components: { DateInput },
  mixins: [FinancialMixin],

  created() {
    this.setup();
  },

  data() {
    return {
      params: {
        payout: null,
        paymentDay: null
      },
      columns: [
        {
          name: 'affiliate_id',
          required: true,
          label: 'ID Afiliado',
          align: 'left',
          field: 'affiliate_id',
          sortable: true
        },
        {
          name: 'affiliate',
          required: true,
          label: 'Afiliado',
          align: 'left',
          field: (row) => row.affiliate.name,
          sortable: true
        },
        {
          name: 'date',
          required: true,
          label: 'Data de Pagamento',
          align: 'left',
          field: 'payment_day',
          format: (val) => this.$moment(val).format('L'),
          sortable: true
        },
        {
          name: 'total_commissions',
          required: true,
          label: 'Valor da Fatura',
          align: 'left',
          field: 'total_commissions',
          format: (total_commissions) => `R$ ${total_commissions}`,
          sortable: true
        },
        {
          name: 'fatura',
          required: true,
          label: 'Fatura',
          align: 'left',
          field: 'invoice_payments_ids',
          sortable: true
        },
        {
          name: 'status',
          required: true,
          label: 'Status',
          align: 'left',
          field: 'id_states',
          format: (val) => this.formatStatus(val),
          sortable: true
        },
        {
          name: 'actions',
          required: true,
          label: 'Ações',
          align: 'center',
          sortable: true
        }
      ],
      pagination: {
        sortBy: 'trademark',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getInvoicesPaymentsStep(`%26page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  methods: {
    formatStatus(value) {
      switch (value) {
        case 4:
          return 'Fatura Agendada';
        case 5:
          return 'Fatura Paga';
        case 12:
          return 'Fatura não Paga';
        default:
          return 'Não Definido';
      }
    },
    setup() {
      let filterSelect = [];
      filterSelect.push('page=1');
      filterSelect.push('perPage=10');
      filterSelect.push('order_by=trademark%7Casc');
      filterSelect.push('states=%5B4%5D');

      this.ActionSetFilterInvoicesPaymentsStep(filterSelect.join('%26'));
      this.getInvoicesPaymentsStep(`%26page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
    },

    async onBtnSendClicked() {
      console.log('data', this.invoicesPaymentsStep);

      this.onLoading(true);

      try {
        this.dialog = false;

        let formUpdate = [];

        this.invoicesPaymentsStep.map((item) => {
          if (item.payout !== 0) {
            for (let i = 0; i < item.invoice_payments_ids.split(',').length; i++) {
              formUpdate.push({
                invoices_payments_id: item.invoice_payments_ids.split(',')[i],
                payment_day: item.payment_day,
                payout: parseInt(item.payout),
                payment_status: parseInt(item.payout) === 2 ? 1 : 3,
                id_states: parseInt(item.payout) === 2 ? 12 : 5
              });
            }
          }
        });

        let request = {
          app: 'finance',
          path: '/api/invoices_payments/',
          params: {
            data: {
              invoices: JSON.stringify(formUpdate)
            }
          }
        };

        const { status } = await this.$http.put('v2/redirect', request);

        if (status === 200) {
          this.successNotify('Fatura atualizada com sucesso');

          this.setup();
        }
      } catch (error) {
        console.log('error ', error);
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>
